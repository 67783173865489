import { environment } from 'src/environments/environment';
import S3RequestPart from '../interfaces/common/S3RequestPart';

class ImageHelper {
    static generateImageUrl(fileName, fileExt) {
        return `${environment.s3Url}/${fileName}.${fileExt}`;
    }

    static generateFileParts(image: string, fileName: string, fileExt: string): S3RequestPart[] {
        const fileParts: S3RequestPart[] = [];
        // debugger;
        const base64 = btoa(image);
        let bufferPosition = 0;
        const bufferSize = 1000000;

        while (bufferPosition < base64.length) {
            const start = bufferPosition;
            bufferPosition += bufferSize;
            const end = bufferPosition < base64.length ? bufferPosition : base64.length;
            fileParts.push({
                bucketName: environment.s3BucketName,
                fileExt,
                fileName,
                serializedObject: base64.substring(start, end),
                part: fileParts.length + 1,
                totalParts: 0
            });
        }

        fileParts.forEach((filePart) => {
            filePart.totalParts = fileParts.length;
        });
        return fileParts;
    }

}

export default ImageHelper;
