import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import S3RequestPart from 'src/app/interfaces/common/S3RequestPart';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  apiUrl: string;

  HttpHeaders = new HttpHeaders({
    accept: 'application/json, text/plain, */*',
    'Cache-Control': 'no-cache'
  });

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}/planted`;
  }

  uploadImage(image: S3RequestPart) {
    return this.httpClient.post<any>(`${environment.apiUrl}/aws/s3/upload`, image, { headers: this.HttpHeaders.append('Timeout', '-1') });
  }
}
